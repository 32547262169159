import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  hashHistory,
  useNavigate,
  useHref,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from "../login/login";
import ProtectedRouter from "../api/protectedRouter";
import Dashboard from "../page/Dashboard/dashboard";
import CertificateList from "../page/Certificate/CertificateList";
import StudentList from "../page/StudentList";
import InvoiceList from "../page/Invoice/InvoiceList";
import InvoiceDetails from "../page/Invoice/InvoiceDetails";
import InvoicePrint from "../page/Invoice/InvoicePrint";
import EnrollmentList from "../page/Enrollment/EnrollmentList";
import EnrollmentStudent from "../page/Enrollment/EnrollmentStudent";
import CategoryWiseIncome from "../page/CategoryWiseIncome";
import Top_Instructors from "../page/Top_Instructors";
import CourseCategory from "../page/CourseCategory";
import Navbar from "../page/Navbar";
import ProfileSetting from "../page/Profile/ProfileSetting";
import Income from "../page/Income";
let session = localStorage.getItem('token');
const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <ProtectedRouter Component={Login} />,
  // },
  {
    path: "login",
    element: <ProtectedRouter Component={Login} />,
  },
  {
    path: "/",
    element: <ProtectedRouter Component={Navbar} />,
    children: [
  
      {
        path: "profile",
        element: <ProtectedRouter Component={ProfileSetting} />,
      },
      {
        path: "certificate",
        element: <ProtectedRouter Component={CertificateList} />,
      },
    ],
  },
]);
export default router;
