import React, { useEffect, useState, useRef } from "react";
import apiService from "../../api/services/api_service";
import axios from "axios";
import Loader from "../Loader";
import ToastMsg from "../Toast";
import ExcelViews from "../ExcelViews";
import Excel from "exceljs";
import * as xlsx from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const certificateList = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [show1, setShow] = useState(false);
  const [uploadFilePath, setUploadFilePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [excelData, setExcelData] = useState("");
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const ref = useRef();
  const reset = () => {
    ref.current.value = "";
  };
 
  const certificateList = async (query="") => {
    const {take,skip}=query
    const url=query?`get-all-certificate?take=${take}&skip=${skip}`:'get-all-certificate'
    const list = (await apiService.apiAuthService("get", url))
      .data;
    setTotal(list?.data?.total)
    setData(list.data?.data);
  };
  useEffect(() => {
    certificateList();
  }, [0]);
  const saveCertificate = async () => {
    const save = await apiService.apiAuthService(
      "post",
      "bulk-create-certificate",
      certificateData
    );

    const id = toast.loading("Please wait...");
    if (save?.data?.success == "failed") {
      const msg = save?.data?.message;
      return <ToastMsg message={msg} />;
    }
    if (save?.data?.message == "Success") {
      toast.update(id, {
        render: "All is good",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  const certificateViews = () => {
    setModalShow(true);
  };
  const saveFile = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData(); //formdata object
    formData.append("file", file);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    // read excel data
    event.preventDefault();
    if (event.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setExcelData(json);
        setIsExcelLoading(true);
        setCertificateData(json);
      };
      reader.readAsArrayBuffer(file);
    }

    // apiService.uploadExcelCertificate(formData)
  };
  const columnLabels = [
    "Registration No",
    "Name",
    "Father's Name",
    "Mother's Name",
    "DOB",
    "Course Name",
    "Duration",
    "Study Centre",
    "Centre Code",
    "%",
    "Grade",
    "Performance",
    "SL No",
    "DOI",
    "Active",
  ];
  
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Certificate</h3>
                  </div>
                  <div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand me-n1"
                        data-target="more-options"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul className="nk-block-tools g-3">
                          <li>
                            <div className="form-control-wrap">
                              <div className="form-icon form-icon-right">
                                <em className="icon ni ni-search"></em>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="default-04"
                                placeholder="Search by name"
                              />
                            </div>
                          </li>
                          <li>
                            <div className="drodown">
                              <a
                                href="#"
                                className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                data-bs-toggle="dropdown"
                              >
                                Status
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <a href="#">
                                      <span>Actived</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <span>Inactived</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <span>Blocked</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li className="nk-block-tools-opt">
                            <a
                              className="btn btn-icon btn-primary d-md-none"
                              data-bs-toggle="modal"
                              href="#student-add"
                            >
                              <em className="icon ni ni-plus"></em>
                            </a>
                            <a
                              className="btn btn-primary d-none d-md-inline-flex"
                              data-bs-toggle="modal"
                              href="#student-add"
                            >
                              <em className="icon ni ni-plus"></em>
                              <span>Add</span>
                            </a>
                          </li>
                          <li className="nk-block-tools-opt">
                            <a
                              className="btn btn-icon btn-primary d-md-none"
                              data-bs-toggle="modal"
                              href="#student-add"
                            >
                              <em className="icon ni ni-plus"></em>
                            </a>
                            <a
                              className="btn btn-danger d-none d-md-inline-flex"
                              data-bs-toggle="modal"
                              href="#student-add-upload-certificate"
                            >
                              <em className="icon ni ni-upload"></em>
                              <span>Upload Certificate</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="card">
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="uid"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="uid"
                              ></label>
                            </div>
                          </div>{
                            columnLabels.map((val,index)=>(
                              
                             <div className="nk-tb-col col-mg-12" key={index}>
                            <span className="sub-text">{val}</span>
                          </div>
                            ))
                          }
                           
                        </div>
                        {
                          data.map((val,index)=>(
                            <div className="nk-tb-item fc-scroller" key={index}>
                            <div className="nk-tb-col nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="uid1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="uid1"
                                ></label>
                              </div>
                            </div>
                            <div className="nk-tb-col">
                              <a href="students-details.html">
                                <div className="user-card">
                                   
                                  <div className="user-info">
                                    <span className="tb-lead">
                                    {val.registration_no}
                                      <span className="dot dot-success d-md-none ms-1"></span>
                                    </span>
                                    
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="nk-tb-col tb-col-mb">
                              <span className="tb-lead d-lg-flex d-none">
                              {val.name}
                              </span>
                             
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span>{val.father_name}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.mother_name}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                              <span className="badge badge-dot badge-dot bg-warning">
                                {val.dob}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-status text-success">
                                {val.course_name}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.duration}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.study_centre}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.centre_code}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.percentage}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.grade}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.performance}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.sl_no}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.date_of_issue}</span>
                            </div>
                            <div className="nk-tb-col tb-col-lg">
                            <span>{val.is_active}</span>
                            </div>
                             
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1">
                                <li className="nk-tb-action-hidden">
                                  <a
                                    href="#"
                                    className="btn btn-trigger btn-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Send Email"
                                  >
                                    <em className="icon ni ni-mail-fill"></em>
                                  </a>
                                </li>
                                <li className="nk-tb-action-hidden">
                                  <a
                                    href="#"
                                    className="btn btn-trigger btn-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Suspend"
                                  >
                                    <em className="icon ni ni-user-cross-fill"></em>
                                  </a>
                                </li>
                                <li>
                                  <div className="drodown">
                                    <a
                                      href="#"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        <li>
                                          <a href="students-details.html">
                                            <em className="icon ni ni-eye"></em>
                                            <span>View Details</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">
                                            <em className="icon ni ni-activity-round"></em>
                                            <span>Activities</span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          ))
                        }
                   
                        
                       
                      </div>
                    </div>
                    <div className="card-inner">
                      <div className="nk-block-between-md g-3">
                        <div className="g">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <em className="icon ni ni-chevrons-left"></em>
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <span className="page-link">
                                <em className="icon ni ni-more-h"></em>
                              </span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                6
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                7
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <em className="icon ni ni-chevrons-right"></em>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="g">
                          <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                            <div>Page</div>
                            <div>
                              <select
                                className="form-select js-select2"
                                data-search="on"
                                data-dropdown="xs center"
                              >
                                <option value="page-1">1</option>
                                <option value="page-2">2</option>
                                <option value="page-4">4</option>
                                <option value="page-5">5</option>
                                <option value="page-6">6</option>
                                <option value="page-7">7</option>
                                <option value="page-8">8</option>
                                <option value="page-9">9</option>
                                <option value="page-10">10</option>
                                <option value="page-11">11</option>
                                <option value="page-12">12</option>
                                <option value="page-13">13</option>
                                <option value="page-14">14</option>
                                <option value="page-15">15</option>
                                <option value="page-16">16</option>
                                <option value="page-17">17</option>
                                <option value="page-18">18</option>
                                <option value="page-19">19</option>
                                <option value="page-20">20</option>
                              </select>
                            </div>
                            <div>OF {total}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* upload certificate */}
      <div
        className="modal fade "
        role="dialog"
        id="student-add-upload-certificate"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <a href="#" className="close" data-bs-dismiss="modal">
              <em className="icon ni ni-cross-sm"></em>
            </a>
            <div className="modal-body modal-body-md m-1 p-1">
              <h5 className="title">Upload Certificate Excel</h5>
              <input
                type="file"
                accept=".xlsx"
                onChange={saveFile}
                ref={ref}
                className="btn btn-warning m-1 p-1"
              ></input>
              <button
                type="button"
                className="btn btn-danger m-1 p-1"
                onClick={reset}
              >
                reset
              </button>
              <button
                type="button"
                className="btn btn-info m-1 p-1"
                onClick={certificateViews}
              >
                View
              </button>
              <button
                type="button"
                className="btn btn-success m-1 p-1"
                onClick={saveCertificate}
              >
                Upload Certificate
              </button>

              {isExcelLoading ? (
                <>
                  <div className="d-flex flex-row flex-nowrap overflow-auto">
                    <div
                      className="card card-block mx-2"
                      style={{ minWidth: "300px" }}
                    >
                      <ExcelViews
                        data={excelData}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* <button className="btn btn-success" onClick={saveFile}>Save Certificate Data</button> */}
              {isLoading ? <Loader /> : ""}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default certificateList;
