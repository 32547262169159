import React,{useEffect} from 'react'
import { Route, useNavigate } from 'react-router-dom';
const  protectedRouter=(props)=> {
  const navigate = useNavigate();
  const {Component} =props
  useEffect(()=>{
    const token=localStorage.getItem('token')
    console.log('token', token)
    const isToken=!token||token==undefined||token==null||token=="undefined"
    if(isToken){
      navigate("/login");
    }
  },[0])
 
  return (
    <>
    <Component/>
    </>
  )
}

export default protectedRouter