import React from "react";

const profileSetting = () => {
  return (
    <div>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head">
                        <div className="nk-block-between d-flex justify-content-between">
                          <div className="nk-block-head-content">
                            <h4 className="nk-block-title">
                              Personal Information
                            </h4>
                            <div className="nk-block-des">
                              <p>
                                Basic info, like your name and address, that you
                                use on Nio Platform.
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-center">
                            <div className="nk-tab-actions me-n1">
                              <a
                                className="btn btn-icon btn-trigger"
                                data-bs-toggle="modal"
                                href="#profile-edit"
                              >
                                <em className="icon ni ni-edit"></em>
                              </a>
                            </div>
                            <div className="nk-block-head-content align-self-start d-lg-none">
                              <a
                                href="#"
                                className="toggle btn btn-icon btn-trigger"
                                data-target="userAside"
                              >
                                <em className="icon ni ni-menu-alt-r"></em>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          <div className="data-head">
                            <h6 className="overline-title">Basics</h6>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Full Name</span>
                              <span className="data-value">
                                Abu Bin Ishtiyak
                              </span>
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Display Name</span>
                              <span className="data-value">Ishtiyak</span>
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Email</span>
                              <span className="data-value">
                                info@softnio.com
                              </span>
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Phone Number</span>
                              <span className="data-value text-soft">
                                Not add yet
                              </span>
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Date of Birth</span>
                              <span className="data-value">29 Feb, 1986</span>
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Nationality</span>
                              <span className="data-value">Canadian</span>
                            </div>
                          </div>
                          <div className="data-item" data-tab-target="#address">
                            <div className="data-col">
                              <span className="data-label">Address</span>
                              <span className="data-value">
                                2337 Kildeer Drive,
                                <br />
                                Kentucky, Canada
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="nk-data data-list">
                          <div className="data-head">
                            <h6 className="overline-title">Preferences</h6>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Language</span>
                              <span className="data-value">
                                English (United State)
                              </span>
                            </div>
                            <div className="data-col data-col-end">
                              <a
                                data-bs-toggle="modal"
                                href="#modalLanguage"
                                className="link link-primary"
                              >
                                Change Language
                              </a>
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Date Format</span>
                              <span className="data-value">M, D, YYYY</span>
                            </div>
                            <div className="data-col data-col-end">
                              <a
                                data-bs-toggle="modal"
                                href="#modalDate"
                                className="link link-primary"
                              >
                                Change
                              </a>
                            </div>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Timezone</span>
                              <span className="data-value">
                                Bangladesh (GMT +6:00)
                              </span>
                            </div>
                            <div className="data-col data-col-end">
                              <a
                                data-bs-toggle="modal"
                                href="#modalTimezone"
                                className="link link-primary"
                              >
                                Change
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg"
                      data-content="userAside"
                      data-toggle-screen="lg"
                      data-toggle-overlay="true"
                    >
                      <div className="card-inner-group" data-simplebar>
                        <div className="card-inner">
                          <div className="user-card">
                            <div className="user-avatar bg-primary">
                              <span>AB</span>
                            </div>
                            <div className="user-info">
                              <span className="lead-text">
                                Abu Bin Ishtiyak
                              </span>
                              <span className="sub-text">info@softnio.com</span>
                            </div>
                            <div className="user-action">
                              <div className="dropdown">
                                <a
                                  className="btn btn-icon btn-trigger me-n2"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                >
                                  <em className="icon ni ni-more-v"></em>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <a href="#">
                                        <em className="icon ni ni-camera-fill"></em>
                                        <span>Change Photo</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <em className="icon ni ni-edit-fill"></em>
                                        <span>Update Profile</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="user-account-info py-0">
                            <h6 className="overline-title-alt">
                              Account Balance
                            </h6>
                            <div className="user-balance">
                              12.395769
                              <small className="currency currency-btc">
                                USD
                              </small>
                            </div>
                            <div className="user-balance-sub">
                              Pending
                              <span>
                                0.344939
                                <span className="currency currency-btc">
                                  USD
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <ul className="link-list-menu">
                            <li>
                              <a className="active" href="admin-profile.html">
                                <em className="icon ni ni-user-fill-c"></em>
                                <span>Personal Infomation</span>
                              </a>
                            </li>
                            <li>
                              <a href="admin-profile-notification.html">
                                <em className="icon ni ni-bell-fill"></em>
                                <span>Notifications</span>
                              </a>
                            </li>
                            <li>
                              <a href="admin-profile-activity.html">
                                <em className="icon ni ni-activity-round-fill"></em>
                                <span>Account Activity</span>
                              </a>
                            </li>
                            <li>
                              <a href="admin-profile-setting.html">
                                <em className="icon ni ni-lock-alt-fill"></em>
                                <span>Security Settings</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="card-inner">
                          <div className="user-account-info py-0">
                            <h6 className="overline-title-alt">Last Login</h6>
                            <p>06-29-2020 02:39pm</p>
                            <h6 className="overline-title-alt">Login IP</h6>
                            <p>192.129.243.28</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default profileSetting;
