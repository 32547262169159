import React, { useEffect, useState } from "react";
import apiService from "../api/services/api_service";
import Loader from "./Loader";
const Income = () => {
  const [total, setTotal] = useState([]);
  const [currentMonthIncome, setCurrentMonthIncome] = useState([]);
  const [dailyIncome,setDailyIncome]=useState([]);
  const [dailyIncomeDate,setDailyIncomeDate]=useState([]);
  const [lastIncomeDate,setLastIncomeDate]=useState([]);
  const [perData,setPerData]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const totalIncome = async () => {
    const total = (
      await apiService.apiAuthService(
        "get",
        "course-total-income-current-month"
      )
    ).data;
    
    setLastIncomeDate(total?.data?.last_month?.total)
    setDailyIncome(total?.data?.daily?.income)
    // setPerData(total?.data?.percentage)
    setDailyIncomeDate(total?.data?.daily.date)
    setCurrentMonthIncome(total?.data?.current_month);
    setTotal(total?.data?.total);
    if(total?.data?.current_month){
      setIsLoading(false)
    }
  };
  useEffect(() => {
    totalIncome();
  }, [0]);

  return (
    <>
      <div className="col-md-4">
        <div className="card">
          <div className="nk-ecwg nk-ecwg3">
            <div className="card-inner pb-0">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title">Total Income</h6>
                </div>
              </div>
              <div className="data">
                <div className="data-group">
                {isLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div className="amount fw-normal">₹{total}</div>
                    )}
                  
                  <div className="info text-end">
                    <span className="change up text-danger">
                      <em className="icon ni ni-arrow-long-up"></em>
                      ₹{lastIncomeDate}
                    </span>
                    <br />
                    <span>last month</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-ecwg3-ck">
              <canvas className="courseSells" id="totalSells"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card">
          <div className="nk-ecwg nk-ecwg3">
            <div className="card-inner pb-0">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title">
                    This{" "}
                    <spam style={{ color: "blue" }}>
                      {currentMonthIncome.month}{" "}
                    </spam>
                    so far
                  </h6>
                </div>
              </div>
              <div className="data">
                <div className="data-group">
                  <div className="amount fw-normal">
                    {isLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div>₹{currentMonthIncome.total}</div>
                    )}
                  </div>
                  <div className="info text-end">
                    <span className="change up text-danger">
                      <em className="icon ni ni-arrow-long-up"></em>
                      7.13%
                    </span>
                    <br />
                    <span>vs. last week</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-ecwg3-ck"></div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card">
          <div className="nk-ecwg nk-ecwg3">
            <div className="card-inner pb-0">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title">
                    Date{" "}
                    <spam style={{ color: "blue" }}>
                      {dailyIncomeDate}{" "}
                    </spam>
                    so far
                  </h6>
                </div>
              </div>
              <div className="data">
                <div className="data-group">
                  <div className="amount fw-normal">
                    {isLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <div>₹{dailyIncome*120}</div>
                    )}
                  </div>
                  <div className="info text-end">
                    <span className="change up text-danger">
                      <em className="icon ni ni-arrow-long-up"></em>
                      ₹{lastIncomeDate}
                    </span>
                    <br />
                    <span>last month</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-ecwg3-ck"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Income;
