import React, { useEffect, useState } from "react";
import "./login.css";
import apiService from "../api/services/api_service";
import { useFormik } from 'formik';
import { Route, useNavigate, NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function login() {
  const [isToken, setIsToken]=useState('')
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password:"",
    },
    onSubmit:async (values) => {
      const data =  (await apiService.userLogin(values)).data
      if(data?.status==400){
        toast.error(`${data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
          });
      }
      const userProfile=JSON.stringify(data?.data?.modify?.centre_info)
      const userRole=JSON.stringify(data?.data?.modify?.role)
      const setToken=data?.data?.token
      localStorage.setItem('token',  setToken)
      localStorage.setItem('userProfile',  userProfile)
      localStorage.setItem('userRole',  userRole)
      const token=data?.data?.token
      if (token) {
        navigate("/");
      }
    },
  });
  useEffect(()=>{
   const token= localStorage.getItem("token")
    if (token) {
      navigate("/");
    }
  },[0])
  return (
    <>
      <div className="container">
        <div className="screen">
          <div className="screen__content">
            <form className="login" onSubmit={formik.handleSubmit}>
              <div className="login__field">
                <i className="login__icon fas fa-user"></i>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className="login__input"
                  placeholder="email id"
                />
              </div>
              <div className="login__field">
                <i className="login__icon fas fa-lock"></i>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className="login__input"
                  placeholder="Password"
                />
              </div>
              <button type="submit" className="button login__submit">
                <span className="button__text">Log In Now</span>
                <i className="button__icon fas fa-chevron-right"></i>
              </button>
            </form>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
}

export default login;
