import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function toastMsg(props) {
    const _toast=props
 
    const msg=()=>{
    toast.success(`${_toast.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    }
    useEffect(()=>{
        msg()
    },[0])
  return (
    <div>
        {msg}
        <ToastContainer />
    </div>
  )
}

export default toastMsg