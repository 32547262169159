/* eslint-disable react/react-in-jsx-scope */
 
import './App.css';
// import Router from './router/router';

import 'react-toastify/dist/ReactToastify.css';
import RouterPath from './router/router';
import { Routes } from 'react-router-dom';
function App() {
  return (
   <>
   
   <RouterPath/>

   </>
  );
}

export default App;
