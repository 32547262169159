import React, { useEffect, useState } from "react";
import Spreadsheet from "react-spreadsheet";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import * as XLSX from "xlsx";
import './ExcelViews.css'
import { useNavigate } from "react-router-dom";
function ExcelViews(props) {
  const navigation=useNavigate()
  const columnLabels = [
    "Registration No",
    "Name",
    "Father's Name",
    "Mother's Name",
    "DOB",
    "Course Name",
    "Duration",
    "Study Centre",
    "Centre Code",
    "Written",
    "Practical",
    "Assignment",
    "Project",
    "Viva",
    "%",
    "Grade",
    "Performance",
    "SL No",
    "DOI",
    "Active",
  ];
  const _readExcel = props;
  const [data, setData] = useState([]);
  const loadExcelData = () => {
    const certificateData = [];
    const ddd = _readExcel?.data.map((item) => {
      const key = Object.values(item);
      return key.map((val) => {
        return {
          value: val,
        };
      });
    });
    certificateData.push(ddd);
    setData(ddd);
  };

  useEffect(() => {
    loadExcelData();
  }, [0]);
  //  const hide=props.onHide
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h6>Student Certificate List</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <Row>
              <Col
              
              >
                <div className="card card-block m-2 p-2">
                  {/* view data */}
                  <div className="viewer">
                    {data ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              {columnLabels.map((key) => (
                                
                                <th key={key}>{key}</th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {_readExcel.data.map((individualExcelData, index) => (
                              <tr key={index}>
                                {Object.keys(individualExcelData).map((key) => (
                                  <td key={key}>{individualExcelData[key]}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>No File is uploaded yet!</div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={closeToDashboard}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExcelViews;
