import axios from "axios";
// require('dotenv').config();
const BASE_URL = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");
const tokenPas = {
  headers: {
    Authorization: `bearer ${token}`,
  },
};
/**
 * // Auth api services
 * @param {*} apiMethod
 * @param {*} url
 * @param {*} payload
 * @returns
 */
const apiAuthService = async (apiMethod, url, payload = "") => {
  switch (apiMethod) {
    case "get":
      return await axios.get(`${BASE_URL}/${url}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

    case "post":
      return await axios.post(`${BASE_URL}/${url}`, payload,{
        
        headers: {
          Authorization: `bearer ${token}`
          ,
        },
      });
    case "patch":
      return await axios.patch(`${BASE_URL}/${url}`, payload, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

    default:
      break;
  }
};

const getCertificate = async (centre_code) => {
  const isReg =
    centre_code == "undefined" || centre_code == null ? "" : centre_code;
  let isAdmin;
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  if (userProfile.centre_type == 1) {
    isAdmin = "";
  } else {
    isAdmin = userProfile?.centre_code;
  }

  return await axios
    .get(`${BASE_URL}/get-all-certificate?centre_code=${isAdmin}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => err);
};

const updateCertificate = async (update) => {
  return await axios
    .patch(`${BASE_URL}/update-certificate`, update, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => err);
};
const reActiveCertificate = async (update) => {
  return await axios
    .patch(`${BASE_URL}/reactive-certificate`, update, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => err);
};

const uploadExcelCertificate = async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return await axios
    .post(`${BASE_URL}/create-certificate-upload`, data, config)
    .then((res) => res)
    .catch((err) => err);
};
// upload file
const readExcelFiles = async (dataRead) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return await axios
    .post(`${BASE_URL}/read-excel-file`, dataRead, tokenPas)
    .then((res) => res)
    .catch((err) => console.log("error:", err));
};
const userLogin = async ({ email, password }) => {
  return await axios
    .post(`${BASE_URL}/login`, {
      email,
      password,
    })
    .then((result) => {
      
      return result;
    })
    .catch((err) => console.log("error:", err));
};

const enrollment = async (data) => {
  return await axios
    .post(
      `${BASE_URL}/mobile-student-enrollment`,
      {
        data,
      },
      tokenPas
    )
    .then((res) => res.data);
};

const passwordForgat = (email) => {
  return axios.post(`${BASE_URL}/login-forgat-password`, email);
};
const apiService = {
  getCertificate,
  userLogin,
  updateCertificate,
  reActiveCertificate,
  uploadExcelCertificate,
  readExcelFiles,
  enrollment,
  passwordForgat,
  apiAuthService,
};
export default apiService;
